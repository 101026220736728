import React, { useState, useRef, useEffect } from 'react';
import './site.css';

import WebGLComponent from './webglComponent';
import Logo from './artwork/logo';

const useAnimationFrame = callback => {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = time => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime)
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }
  
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once
};

const useBrowserDimensions = () => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const update = () => {
    setWidth(window.outerWidth);
    setHeight(window.outerHeight);
  };
  useEffect(() => {
    update();
    window.addEventListener('resize', update);
  });
  return [width, height];
};

let coordinatesLocation = undefined;
let patternIntensityLocation = undefined;
let alphaLocation = undefined;
let betaLocation = undefined;
let offsetLocation = undefined;

const initializeGraphics = (gl, vertexShaderSource, fragmentShaderSource) => {
  const vertices = [
    -1, -1,
    1, -1,
    -1, 1,
    1, -1,
    -1, 1,
    1, 1,
  ];

  const vertexBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);
  gl.bindBuffer(gl.ARRAY_BUFFER, null);

  const vertexShader = gl.createShader(gl.VERTEX_SHADER);
  gl.shaderSource(vertexShader, vertexShaderSource);
  gl.compileShader(vertexShader);

  const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
  gl.shaderSource(fragmentShader, fragmentShaderSource);
  gl.compileShader(fragmentShader);

  const shaderProgram = gl.createProgram();
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  console.log('Vertex Shader info:', gl.getShaderInfoLog(vertexShader) || 'OK');
  console.log('Fragment Shader info:', gl.getShaderInfoLog(fragmentShader) || 'OK');
  console.log('Shader Program info:', gl.getProgramInfoLog(shaderProgram) || 'OK');

  gl.useProgram(shaderProgram);

  coordinatesLocation = gl.getAttribLocation(shaderProgram, "coordinates");
  alphaLocation = gl.getUniformLocation(shaderProgram, "alpha");
  betaLocation = gl.getUniformLocation(shaderProgram, "beta");
  patternIntensityLocation = gl.getUniformLocation(shaderProgram, "patternIntensity");
  offsetLocation = gl.getUniformLocation(shaderProgram, "offset");

  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.vertexAttribPointer(coordinatesLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(coordinatesLocation);
}

let t = 0.0;

// const squareAngles = [Math.PI / 2.0, Math.PI / 2.0];

const pentagonAngles = [Math.PI / 10.0, Math.PI / 5.0];
const angleConfig2 = [Math.PI / 3.9, Math.PI / 4.5];
const angleConfig3 = [-Math.PI / 10.0, Math.PI / 3.0];

let targetT = 0.0;
let offset = 0.0;
let targetOffset = 0.0;
let targetPentagoniness = 0;
const nudge = () => {
  targetT = Math.max(t, targetT) + Math.PI;
  targetOffset = Math.max(offset, targetOffset) + Math.PI / 4;
  targetPentagoniness = !targetPentagoniness;
  function f() {
    t = t * 0.99 + targetT * 0.01;
    offset = offset * 0.99 + targetOffset * 0.01;
    if (Math.abs(targetOffset - offset) > 0.01) {
      requestAnimationFrame(f);
    }
  };
  f();
}

let patternIntensity = 0.0;

const renderGraphics = (gl, browserDimensions) => {
  if (patternIntensity < 1.0) {
    patternIntensity += 0.01;
  }

  t += 0.01;
  const angles = [0, 1].map(comp => {
    const angleMix = angleConfig2[comp] * Math.pow(Math.sin(t*0.17), 2) + angleConfig3[comp] * Math.pow(Math.cos(t*0.17), 2);
    return angleMix * Math.pow(Math.sin(t * 0.2 + offset), 2) +
           pentagonAngles[comp] * Math.pow(Math.cos(t * 0.2 + offset), 2);
  });

  gl.viewport(0, 0, browserDimensions[0], browserDimensions[1]);
  gl.enableVertexAttribArray(coordinatesLocation);
  gl.uniform1f(alphaLocation, angles[0]);
  gl.uniform1f(betaLocation, angles[1]);
  gl.uniform1f(offsetLocation, Math.pow(Math.sin(offset), 2));
  gl.uniform1f(patternIntensityLocation, patternIntensity);
  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 6);
  gl.disableVertexAttribArray(coordinatesLocation);
}

const Pattern = props => {
  const [vertexShader, setVertexShader] = useState();
  const [fragmentShader, setFragmentShader] = useState();
  const browserDimensions = useBrowserDimensions();

  useEffect(() => {
    (async () => {
      try {
        const promises = [fetch('./logovertex.glsl'), fetch('./logofragment.glsl')];
        const shaders = await Promise.all(promises);
        const [vertexShaderSource, fragmentShaderSource] =
          await Promise.all(shaders.map(shader => shader.text()));
        setVertexShader(vertexShaderSource);
        setFragmentShader(fragmentShaderSource);
      } catch (e) {
        props.onFail();
      }
    })();
  }, []);

  if (vertexShader && fragmentShader) {
    const render = gl => renderGraphics(gl, browserDimensions);
    const initialize = gl => {
      try {
        initializeGraphics(gl, vertexShader, fragmentShader);
      } catch (e) {
        props.onFail();
        return null;
      }
      props.onInitialize();
    }

    return <WebGLComponent
      size={browserDimensions}
      className="background"
      initialize={initialize}
      render={render} />  
  } else {
    // Still loading shaders.
    return null;
  }
}

const MorphingLogo = () => {
  const [pentagoniness, setPentagoniness] = useState(0)
  useAnimationFrame(deltaTime => {
    setPentagoniness(prevPentagoniness => {
      const newValue = prevPentagoniness +
        (targetPentagoniness - prevPentagoniness) * deltaTime / 400;
      if (Math.abs(newValue - targetPentagoniness) > 0.05) {
        return newValue;
      }
      return targetPentagoniness;
    }); 
  });
  return <Logo pentagoniness={Math.min(pentagoniness, 1)} onClick={nudge}/>;
}

const Site = props => {
  const [contentVisible, setContentVisibility] = useState(false);
  const fadeInContent = () => {
    setContentVisibility(true);
    targetPentagoniness = 1;
  }

  return (<div>
      <Pattern onInitialize={fadeInContent} onFail={fadeInContent}/>
      <div className="overlay">
        <div className={[contentVisible ? 'fadeIn' : '', 'wrapper'].join(' ')}>
          <MorphingLogo/>
          <h1>Hello, I'm Emil. </h1>
          <p>
            I like science, computer graphics, interaction design, open source, music,
            algorithms, software architecture and teamwork.
          </p>
          <ul>
            <li><a href="https://github.com/emiax"><i className="fab fa-github"></i>GitHub</a></li>
            <li><a href="https://www.linkedin.com/in/emiax"><i className="fab fa-linkedin"></i>LinkedIn</a></li>
            <li><a href="https://facebook.com/nils.emil.axelsson"><i className="fab fa-facebook"></i>Facebook</a></li>
            <li><a href="mailto:mail@emilaxelsson.se"><i className="fas fa-envelope"></i>mail@emilaxelsson.se</a></li>
            {<li><a href="https://www.youtube.com/user/EmilAxelsson"><i className="fab fa-youtube"></i>YouTube</a></li>}
            <li><a href="https://scholar.google.se/citations?user=wnSeyRsAAAAJ"><i className="fab fa-google"></i>Google Scholar</a></li>
          </ul>
        </div>
      </div>
  </div>);
}
  

export default Site;
