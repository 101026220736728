import React from 'react';

import { translate, scale, mixPointArrays, polylineString } from './vectors';

import {
  regularE,
  pentagonE,
  regularA,
  pentagonA,
  pentagonELine,
  regularELine
} from './ea-constants';


export default ({onClick, pentagoniness}) => {
  const size = [35, -35];
  const offset = [6, 60];

  const eLetter = mixPointArrays(translate(regularE, [-0.20, 0]), pentagonE, pentagoniness);
  const aLetter = mixPointArrays(translate(regularA, [0.1, 0]), pentagonA, pentagoniness);
  const eLine = mixPointArrays(translate(regularELine, [-0.20, 0]), pentagonELine, pentagoniness);

  const eLinePoints = translate(scale(eLine, size), offset);
  const ePoints = polylineString(translate(scale(eLetter, size), offset));
  const aPoints = polylineString(translate(scale(aLetter, size), offset));
  return (
    <svg onClick={onClick || (() => {})} className="logo" version="1.1" id="Layer_1" x="0px" y="0px"
       width="64px" height="32px" viewBox="0 0 128 64" enableBackground="new 0 0 128 64" space="preserve">
    <g>
        <polyline fill="none"
                  stroke="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points={ePoints}
        />
        <polyline fill="none"
                  stroke="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points={aPoints}
        />
        
        <line fill="none"
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1={eLinePoints[0][0]}
              y1={eLinePoints[0][1]}
              x2={eLinePoints[1][0]}
              y2={eLinePoints[1][1]}
        />
    </g>
    </svg>);
}