export function scale(points, scale) {
  return points.map((p) => [scale[0] * p[0], scale[1] * p[1]]);
}

export function translate(points, offset) {
 return points.map((p) => [offset[0] + p[0], offset[1] + p[1]]); 
}

export function polylineString(pointArray) {
  return pointArray.map(p => p[0] + "," + p[1]).join(" ");
}

export function mixPoints(a, b, t) {
  return [(1 - t) * a[0] + t * b[0], (1 - t) * a[1] + t * b[1]];
}

export function mixPointArrays(aArray, bArray, t) {
  if (aArray.length !== bArray.length) {
      throw new Error('Cannot interpolate between two point arrays with different lengths ' + aArray.length + ' and ' + bArray.length);
  }
  return aArray.map((a, index) => {
    const b = bArray[index];
    return mixPoints(a, b, t);
  });
}