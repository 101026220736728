import { mixPoints } from './vectors';

const sin = Math.sin;
const cos = Math.cos;
const pi = Math.PI;

const d1 = sin(2*pi/5);
const d2 = sin(4*pi/5);
const d3 = cos(2*pi/5);
const d4 = (1 + 2*d3)/2;
const d5 = sin(pi/10) * (d1 + d2) / 2;

const width = 2 + d3 + d4;
const height = d1 + d2;

const tilingWidth = 4 + 2*d3 - 2*d4;

const a = [d3 + 1, d2 + d1];
const b = [d3, d2 + d1];
const c = [0, d2];
const d = [d4, 0];
const e = [2*d4, d2];
const f = [d4, d2 + d5];
const g = [d4 + 1, 0];
const h = [d3 + 2 - d4, d1];
const i = [d3 + 2, d2 + d1];
const j = [d3 + 2 + d4, d1];
const k = [d4 + 2, 0];
const l = [d3 + 2, d2 + d5];

const points = {
  a, b, c, d, e, f, g, h, i, j, k, l
};

const pentagonE = [a, b, c, d, e];
const pentagonA = [g, h, i, j, k];
const pentagonELine = [c, f];
const pentagon0 = [a, b, c, d, e, a];
const pentagon1 = [g, h, i, j, k, g];

const aPrime = a;
const bPrime = b;
const cPrime = [b[0], f[1]];
const dPrime = [b[0], 0];
const ePrime = [a[0], 0];
const fPrime = f;
const gPrime = [(a[0] + g[0]) / 2, 0];
const iPrime = i;
const kPrime = [2 * iPrime[0] - gPrime[0], 0];
const hPrime = mixPoints(iPrime, gPrime, 0.5);
const jPrime = mixPoints(iPrime, kPrime, 0.5);


const regularE = [aPrime, bPrime, cPrime, dPrime, ePrime];
const regularA = [gPrime, hPrime, iPrime, jPrime, kPrime];
const regularELine = [cPrime, fPrime];

export { width, tilingWidth, height, points, d1, d2, d3, d4, d5, pentagonE, pentagonA, pentagonELine, pentagon0, pentagon1, regularE, regularA, regularELine } 